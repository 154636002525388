import bioPic from "../assets/images/siobhan.png"
import laptop from "../assets/images/laptop.jpeg"
import preOrder from "../assets/images/pre-order.png"
import siobhanNew from "../assets/images/siobhan-new.png"
import Card from "./Card";

const Updates = () => {

    return (<>
    <h2 className="sc-block-heading alignfull has-text-align-left has-text-color updates-h2">
        Updates from the Author
    </h2>
    <div className="container">
        <Card 
            title="Everlasting Rain is live on Amazon!"
            body="I am very excited to announce that the day is finally here, Everlasting Rain is now available to purchase via Amazon. It is available on both Kindle and Paperback, so get your copy now (https://amzn.eu/d/fOnXKxU). Also, please leave a review on Amazon or GoodReads - it would mean a lot to me. Thank you all!"
            date="07/11/23"
            image={siobhanNew}
        />
        <Card 
            title="We have a release date - November 7th!"
            body="You read that right, Everlasting Rain is being released on 7th November 2023 and is available on pre-order NOW! Head over to Amazon to pre-order your kindle or paperback copy. You'll find the link at the top of this page."
            date="01/10/23"
            image={preOrder}
        />
        <Card 
            title="A bit about me."
            body="Hello, my name is Siobhán O'Connor. I write Fantasy/Lesbian Romance. I have worked on my debut novel, Everlasting Rain, for around ten years. I am very excited to finally be able to share it with you. Please enjoy a FREE sneak preview of the prologue and first chapter by signing up to my mailing list below."
            date="16/09/23"
            image={bioPic}
        />
        <Card 
            title="Welcome to my site!"
            body="Hello everyone and welcome to my brand new site! This is where you will find inside information on my debut novel Everlasting Rain which is being released on 7th November 2023 and is available on pre-order NOW! You can sign up to the mailing list to get an exclusive look at the prologue and first chapter. Stay tuned for more."
            date="14/09/23"
            image={laptop}
        />
    </div>
    </>)
}

export default Updates;